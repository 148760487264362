import type { PopUpContent } from '#types/components/cms/pop-up'

export default createGlobalState(() => {
  const dismissed = useLocalStorage<string[]>('dismissedPopups', [])

  const popUp = ref<PopUpContent | null>(null)

  const open = (popUps: PopUpContent[]): void => {
    popUp.value = popUps?.filter(({ id }) => !dismissed.value.includes(id))[0]
  }

  const close = (): void => {
    dismissed.value = [...dismissed.value, popUp.value!.id]
    popUp.value = null
  }

  return {
    close,
    open,
    popUp
  }
})
