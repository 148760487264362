<template>
  <base-picture
    v-if="responsiveMedia.images"
    v-bind="responsiveMedia.images"
    fit="cover"
    class="w-full"
  />
  <base-video
    v-else
    v-bind="video"
    ref="videoRef"
    :controls="false"
    autoplay
    loop
    class="w-full cover"
  />
</template>

<script lang="ts" setup>
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'
import type { PlayerSettings } from '#types/components/cms/video-player'
import type { ResponsiveMedia } from '#types/content'

const { media } = defineProps<{
  media: ResponsiveMedia
  playerSettings?: PlayerSettings
}>()

const { getMedia, getVideo } = useCms()

const responsiveMedia = media && getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video)

    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
