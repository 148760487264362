<template>
  <base-youtube
    v-if="video.id"
    v-bind="video"
    class="aspect-video w-full"
    :controls="!hideControls"
    :autoplay
    :loop
    :muted
  />
  <base-video
    v-else
    v-bind="video"
    ref="videoRef"
    v-style:aspect-ratio="aspectRatio"
    class="w-full"
    :muted="autoplay || muted"
    :controls="!hideControls"
    :autoplay
    :loop
  />
</template>

<script lang="ts" setup>
import type { VideoPlayerContent } from '#types/components/cms/video-player'

const { content } = defineProps<{
  content: VideoPlayerContent
}>()

const {
  media,
  playerSettings: { autoplay, hideControls, loop, muted } = {}
} = content

const { getVideo, getMedia } = useCms()

const aspectRatio = Object.entries(media || {})
  .reduce((acc, [br, { width = 16, height = 9 }]) => ({
    ...acc,
    [br]: width / height
  }), {})

const responsiveMedia = media && getMedia(media)
const video = ref<any>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const getYoutubeId = (url: string): string | undefined => {
  const regex = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))(?<id>[^?&"'>]+)/
  return url.match(regex)?.groups?.id
}

onMounted(() => {
  if ('video' in responsiveMedia) {
    const _video = getVideo(responsiveMedia.video)

    if (!_video || !_video.src) return

    const youtubeId = getYoutubeId(_video.src)

    if (youtubeId) {
      video.value = { id: youtubeId, title: _video.meta?.title }
    }
    else {
      video.value = _video
      videoRef.value?.$el.load()
    }
  }
})
</script>
