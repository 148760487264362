<template>
  <div ref="elementRef" v-style:bg="backgroundColor" class="md:flex" @click="handlePromotionClick">
    <div class="relative flex" :class="mediaSize[imageWidth]">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        class="w-full"
      />
      <base-video
        v-else-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :autoplay="!lazy"
        :controls="false"
        loop
        muted
        class="w-full cover"
      />
      <base-link
        v-if="mediaLink"
        :to="mediaLink.url"
        :target="mediaLink.targetAttribute"
        aria-hidden="true"
        tabindex="-1"
        class="absolute-0"
      />
    </div>
    <div class="px-4 py-6 md:flex-1 md:self-center space-y-2 lg:px-6 lg:space-y-4" :class="contentAlignment">
      <p v-if="overline" v-style:c="overlineColor" :class="overlineStyle" data-test-id="cms-banner-overline">
        {{ overline }}
      </p>
      <h2
        v-if="title"
        v-style:c="titleColor"
        :class="titleStyle"
        data-test-id="cms-banner-title"
      >
        <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
          {{ title }}
        </base-link>
        <template v-else>
          {{ title }}
        </template>
      </h2>
      <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle" data-test-id="cms-banner-text">
        {{ subtitle }}
      </p>
      <cms-rich-text v-if="richText" :content="{ richText }" data-test-id="cms-banner-body" />
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="f-col gap-4 md:flex-row md:wrap lg:gap-6 not-first:pt-4"
        :class="ctaClass"
      >
        <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type {
  BannerSplitAlignmentLarge,
  BannerSplitAlignmentSmall,
  BannerSplitContent,
  BannerSplitMediaWidth
} from '#types/components/cms/banner-split'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: BannerSplitContent
}>()

const {
  alignmentLarge,
  alignmentSmall,
  backgroundColor,
  equalTargetSize,
  imageWidth = '60',
  linkType,
  media,
  mediaTarget,
  overline,
  overlineColor,
  overlineStyle,
  promotionTracking,
  richText,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia, getVideo } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const contentAlignmentLarge: Record<BannerSplitAlignmentLarge, string> = {
  'left-left': 'md:text-left md:order-first',
  'left-right': 'md:text-left md:order-last',
  'center-left': 'md:text-center md:order-first',
  'center-right': 'md:text-center md:order-last',
  'right-left': 'md:text-right md:order-first',
  'right-right': 'md:text-right md:order-last'
}

const contentAlignmentSmall: Partial<Record<BannerSplitAlignmentSmall, string>> = {
  center: '<md:text-center',
  right: '<md:text-right'
}

const ctaAlignmentLarge: Record<BannerSplitAlignmentLarge, string> = {
  'left-left': 'md:justify-start',
  'left-right': 'md:justify-start',
  'center-left': 'md:justify-center',
  'center-right': 'md:justify-center',
  'right-left': 'md:justify-end',
  'right-right': 'md:justify-end'
}

const ctaAlignmentSmall: Record<BannerSplitAlignmentSmall, string> = {
  left: '<md:items-start',
  center: '<md:items-center',
  right: '<md:items-end'
}

const mediaSize: Record<BannerSplitMediaWidth, string> = {
  60: 'md:w-3/5',
  50: 'md:w-1/2',
  40: 'md:w-2/5'
}

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:auto-rows-fr <md:items-stretch',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid md:auto-rows-fr md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

const contentAlignment = [
  contentAlignmentLarge[alignmentLarge],
  contentAlignmentSmall[alignmentSmall]
]

const ctaClass = [
  ctaAlignmentLarge[alignmentLarge],
  ctaAlignmentSmall[alignmentSmall],
  equalTargetSizeClass
]

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
