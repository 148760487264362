<template>
  <cms-experience v-if="popUp" :content="popUp" />
</template>

<script setup lang="ts">
const { getPopUps } = useCms()
const { open, popUp } = usePopUp()

const data = await getPopUps()
if (data) open(data.items)
</script>
