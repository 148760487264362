<template>
  <div ref="elementRef" @click="handlePromotionClick">
    <div class="relative">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        class="w-full"
      />
      <base-video
        v-else-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :autoplay="!lazy"
        :controls="false"
        loop
        muted
        class="w-full cover"
      />
      <base-link
        v-if="mediaLink"
        :to="mediaLink.url"
        :target="mediaLink.targetAttribute"
        aria-hidden="true"
        tabindex="-1"
        class="absolute-0"
      />
    </div>
    <div class="mt-4 f-col px-4 space-y-2 lg:space-y-4" :class="contentAlignment[alignment]">
      <p v-if="title" v-style:c="titleColor" :class="titleStyle" data-test-id="cms-linked-card-title">
        <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
          {{ title }}
        </base-link>
        <template v-else>
          {{ title }}
        </template>
      </p>
      <p
        v-if="subtitle"
        v-style:c="subtitleColor"
        :class="subtitleStyle"
        data-test-id="cms-linked-card-text"
      >
        {{ subtitle }}
      </p>
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="f-col gap-4 md:flex-row md:wrap lg:gap-6 not-first:pt-4"
        :class="[ctaAlignment[alignment], equalTargetSizeClass]"
        :style="`--cols: repeat(${cols},minmax(0,1fr))`"
      >
        <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size="size" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'
import type { LinkedCardAlignment, LinkedCardContent } from '#types/components/cms/linked-card'

const { content, isPartOfCollection } = defineProps<{
  content: LinkedCardContent
  isPartOfCollection?: boolean
}>()

const {
  alignment,
  equalTargetSize,
  linkType,
  media,
  mediaTarget,
  promotionTracking,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle
} = content

const { size } = useAppConfig().components.cms.sharedButton
const { getVideo, getMedia } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const contentAlignment: Partial<Record<LinkedCardAlignment, string>> = {
  center: 'items-center text-center'
}

const ctaAlignment: Record<LinkedCardAlignment, string> = {
  left: 'items-start',
  center: '<md:items-center md:justify-center'
}

const cols = isPartOfCollection ? '1' : targets.length
const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:cols-1 <md:auto-rows-fr',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid cols-$cols auto-rows-fr items-stretch',
  !equalTargetSize.md && getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid cols-$cols auto-rows-fr items-stretch'
].filter(Boolean).join(' ')

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
