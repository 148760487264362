import type { MediaMeta } from '#types/seo'
import type { Responsive, SwappedObject } from '#types/common'
import type { ResponsiveMedia } from '#types/content'
import type { CMSPicture, CMSVideo } from '#types/media'

interface Image {
  src: Responsive
  width: Responsive<number>
  height: Responsive<number>
  alt: string
  meta: Partial<MediaMeta>
}

interface Video {
  src: string
  meta: Partial<MediaMeta>
}

type ResponsiveMediaEntries<T> = [keyof Responsive, T][]

type ResponsiveMediaObject = { images: Image } | { video: SwappedObject<Responsive<Video>> }

/**
 * Generates responsive image sources based on the provided images and URL transformation function.
 *
 * @param images - The array of responsive image entries.
 * @param getUrlFn - The function to transform image URLs.
 * @returns A responsive image object with transformed URLs and metadata.
 * @category Utils
 */
const getPictureSources = (
  images: ResponsiveMediaEntries<CMSPicture>,
  getUrlFn: (url: string) => string
): Image => {
  const [[_, def = {} as CMSPicture]] = images

  return {
    ...images.reduce((acc, [br, image]) => {
      acc.src[br] = getUrlFn(image.url)
      acc.width[br] = image.width
      acc.height[br] = image.height
      return acc
    }, { src: {}, width: {}, height: {} } as Pick<Image, 'src' | 'width' | 'height'>),
    alt: def.alt,
    meta: {
      title: def.seoTitle,
      description: def.seoDescription
    }
  }
}

/**
 * Generates responsive video sources based on the provided videos and URL transformation function.
 *
 * @param videos - The array of responsive video entries.
 * @param getUrlFn - The function to transform video URLs.
 * @returns A responsive object containing transformed video sources with metadata.
 * @category Utils
 */
const getVideoSources = (
  videos: ResponsiveMediaEntries<CMSVideo>,
  getUrlFn: (url: string) => string
) => {
  const obj = videos.reduce((acc, [br, video]) => ({
    ...acc,
    [br]: {
      src: getUrlFn(video.url),
      type: video.contentType,
      width: video.width,
      height: video.height,
      poster: video.posterUrl,
      meta: {
        title: video.seoTitle || video.title,
        description: video.seoDescription
      }
    }
  }), {} as Responsive<Video>)

  return swapObjectKeys(obj)
}

/**
 * Map CMS Media response to base components props structure
 * @param getUrlFn {Function}
 * @param media {ResponsiveMedia}
 * @category Utils
 */
export const getResponsiveMedia = (
  getUrlFn: (url: string) => string,
  media: ResponsiveMedia | null = {} as any
): ResponsiveMediaObject => {
  if (media === null) return {} as ResponsiveMediaObject

  const defaultMedia = media.sm || media.md || media.lg
  const mediaType = defaultMedia?.type

  if (!mediaType) return {} as ResponsiveMediaObject

  const mediaEntries = Object.entries(media)
    .filter(([_, entry]) => entry?.type === mediaType)

  switch (mediaType) {
    case 'CMPicture':
      return {
        images: getPictureSources(mediaEntries as ResponsiveMediaEntries<CMSPicture>, getUrlFn)
      }
    case 'CMVideo':
      return {
        video: getVideoSources(mediaEntries as ResponsiveMediaEntries<CMSVideo>, getUrlFn)
      }
  }
}

/**
 * Retrieves the appropriate video source based on the viewport size.
 *
 * @param video - The responsive video sources.
 * @param viewport - The viewport sizes.
 * @returns The video source for the corresponding viewport or null if no video is provided.
 * @category Utils
 */
export const getVideoForBreakpoint = (video: Responsive<Video>, viewport: Responsive<boolean>): Video | null => {
  if (!video) return null

  switch (true) {
    case viewport.lg:
      return video.lg || video.md || video.sm
    case viewport.md:
      return video.md || video.sm
    default:
      return video.sm
  }
}
