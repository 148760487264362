<template>
  <div class="f-col space-y-6" :class="[{ 'items-center': alignment === 'center' }]">
    <div v-if="mediaPosition === 'top'">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        fit="cover"
        class="w-full"
      />
      <base-video
        v-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :controls="!playerSettings?.hideControls"
        :loop="playerSettings?.loop"
        class="w-full cover"
      />
    </div>
    <div v-if="title || richText" class="space-y-2">
      <h2 v-if="title" v-style:c="titleColor" :class="titleStyle">
        {{ title }}
      </h2>
      <cms-rich-text v-if="richText" :content="{ richText } " />
    </div>
    <div v-if="mediaPosition === 'body'">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        fit="cover"
        class="w-full"
      />
      <base-video
        v-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :controls="!playerSettings?.hideControls"
        :muted="playerSettings?.muted"
        :loop="playerSettings?.loop"
        class="w-full cover"
      />
    </div>
    <div v-if="targets.length" class="flex gap-4 wrap lg:gap-6">
      <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size="{ sm: { size: 'sm' } }" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { DialogContent } from '#types/components/cms/dialog'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: DialogContent
}>()

const {
  alignment,
  media,
  mediaPosition = 'top',
  playerSettings,
  richText,
  targets = [],
  title,
  titleColor,
  titleStyle
} = content

const { getMedia, getVideo } = useCms()

const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      playerSettings?.autoplay && videoRef.value.$el.play()
    }
  }
})
</script>
